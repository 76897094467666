import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { Link } from 'gatsby';
import Animation from '../images/ani-graph.inline.svg';
import Bgimg from '../images/box-mask.svg';
import Bgimg2 from '../images/top-right.svg';

const IndexPage = () => {
    return (
        <Layout>
            <Seo
                title="Life Insurance and Family Planning"
                description="Life Insurance and Family Planning"
            />
            <div className="app-hero">
                <div className="herotxt">
                    <h1
                        data-sal="slide-up"
                        data-sal-duration="500"
                        data-sal-delay="400"
                        data-sal-easing="easeOutCubic"
                    >
                        Three Generation Family Business
                    </h1>
                    <p
                        data-sal="slide-up"
                        data-sal-duration="500"
                        data-sal-delay="600"
                        data-sal-easing="easeOutCubic"
                    >
                        Life insurance &amp; Financial Planning
                    </p>
                    <p
                        data-sal="slide-up"
                        data-sal-duration="500"
                        data-sal-delay="700"
                        data-sal-easing="easeOutCubic"
                    >
                        <Link to="/services" className="btn">
                            Our Services
                        </Link>
                    </p>
                </div>
                <div className="app-ani">
                    <Animation />
                </div>
            </div>
            <div className="solutions-cta">
                <div className="container">
                    <div className="grid">
                        <div className="col-lg-12">
                            <h1>Our Services</h1>
                            <p>Build and protect your assets and wealth</p>
                            <ul>
                                <li
                                    style={{ backgroundImage: `url(${Bgimg})` }}
                                >
                                    <Link to="/services">
                                        <span>
                                            Superannuation{' '}
                                            <div className="elipsis">
                                                Super is a long-term investment
                                                which grows over time to provide
                                                income in retirement..
                                            </div>
                                        </span>
                                    </Link>
                                </li>
                                <li
                                    style={{ backgroundImage: `url(${Bgimg})` }}
                                >
                                    <Link to="/services">
                                        <span>
                                            Insurance{' '}
                                            <div className="elipsis">
                                                Making sure you have the right
                                                level of insurance to protect
                                                you and your family is critical.
                                            </div>
                                        </span>
                                    </Link>
                                </li>
                                <li
                                    style={{ backgroundImage: `url(${Bgimg})` }}
                                >
                                    <Link to="/services">
                                        <span>
                                            Estate Planning{' '}
                                            <div className="elipsis">
                                                Ensuring your loved ones are
                                                provided for should you become
                                                incapacitated or die.
                                            </div>
                                        </span>
                                    </Link>
                                </li>
                                <li
                                    style={{ backgroundImage: `url(${Bgimg})` }}
                                >
                                    <Link to="/services">
                                        <span>
                                            Investment{' '}
                                            <div className="elipsis">
                                                Creating a diversified
                                                investment portfolio in line
                                                with your tolerance to
                                                investment risk.
                                            </div>
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="outro"
                style={{ backgroundImage: `url(${Bgimg2})` }}
            >
                <div className="container">
                    <div className="grid">
                        <div className="col-lg-12">
                            <div className="outrotxt">
                                <h1
                                    data-sal="slide-up"
                                    data-sal-duration="300"
                                    data-sal-easing="easeOutCubic"
                                >
                                    What We Stand For
                                </h1>
                                <p
                                    data-sal="slide-up"
                                    data-sal-duration="300"
                                    data-sal-easing="easeOutCubic"
                                >
                                    As a rare three generation family business,
                                    we stand for honesty, competence and
                                    commitment to our clients.
                                </p>
                                <p
                                    data-sal="slide-up"
                                    data-sal-duration="300"
                                    data-sal-easing="easeOutCubic"
                                >
                                    <Link to="/about" className="btn">
                                        About Us
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default IndexPage;
